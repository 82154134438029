import { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { isGoodpathEmail } from '../../common/utils/isGoodpathEmail'
const hubspot = require('@aaronhayes/react-use-hubspot-form')

// Loads a script asynchronously and returns the status of the load. Usage is:
//   const status = useScript('https://cool-script.com/whatever.js
//
// status will be `ready` when the script is fully loaded.
//
// Got this here: https://usehooks.com/useScript/
//
const useScript = (src) => {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? 'loading' : 'idle')

  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus('idle')
        return
      }

      // Fetch existing script element by src
      // It may have been added by another intance of this hook
      let script = document.querySelector(`script[src="${src}"]`)

      if (!script) {
        // Create script
        script = document.createElement('script')
        script.src = src
        script.async = true
        script.setAttribute('data-status', 'loading')
        // Add script to document body
        document.body.appendChild(script)

        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event) => {
          script.setAttribute(
            'data-status',
            event.type === 'load' ? 'ready' : 'error'
          )
        }

        script.addEventListener('load', setAttributeFromEvent)
        script.addEventListener('error', setAttributeFromEvent)
      } else {
        // Grab existing script status from attribute and set to state.
        setStatus(script.getAttribute('data-status'))
      }

      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event) => {
        setStatus(event.type === 'load' ? 'ready' : 'error')
      }

      // Add event listeners
      script.addEventListener('load', setStateFromEvent)
      script.addEventListener('error', setStateFromEvent)

      // Remove event listeners on cleanup
      return () => {
        if (script) {
          script.removeEventListener('load', setStateFromEvent)
          script.removeEventListener('error', setStateFromEvent)
        }
      }
    },
    [src] // Only re-run effect if script src changes
  )

  return status
}

export const useGoodpatherCheck = (email) => {
  const [, setGoodpatherCookie] = useCookies(['Goodpather'])

  useEffect(() => {
    if (isGoodpathEmail(email)) {
      setGoodpatherCookie('Goodpather', 'i_am_a_GOODpather', {
        maxAge: 51840000,
        path: '/'
      })
    }
  }, [email, setGoodpatherCookie])
}

export const useExternalForm = ({
  portalId,
  formId,
  target,
  onFormSubmitted = () => {}
}) => {
  if (typeof window !== `undefined` && !window.jQuery) {
    window.jQuery = () => {}
  }

  const props = hubspot.useHubspotForm({
    portalId,
    formId,
    target,
    onFormSubmitted
  })

  return props
}

export { useScript }
